<template>
  <div class="error-data mx-auto">
    <h2 class="font-bold text-center text-gray-500">Fehler</h2>
  </div>
</template>

<script>
import {} from "vuex";

export default {
  data: () => ({}),
};
</script>
